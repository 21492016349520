import { BookmarkOutlined } from '@mui/icons-material';
import { IconButton, MenuItem, Typography } from '@mui/material';
import Menu from '@mui/material/Menu';
import * as Sentry from '@sentry/react';
import ConfirmationDialog from 'components/form/standard/ConfirmationDialog';
import { LightTooltip } from 'pages/deal/components/InfoTooltip';
import * as React from 'react';
import { useMemo, useState } from 'react';
import { deleteEmailAsync } from 'utils/email';

export default function EmailActionMenu({ emailId, timelineRefetch }: { emailId?: number; timelineRefetch: () => void }) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const handleDeleteDialogOpen = () => setDeleteDialogOpen(true);
  const handleDeleteDialogClose = () => setDeleteDialogOpen(false);

  const deleteFn = async (id: number | string) => {
    try {
      await deleteEmailAsync(id);
      timelineRefetch();
    } catch (error) {
      Sentry.captureException('Unable to remove Email');
    }
  };

  const handleDelete = useMemo(
    () => async () => {
      if (emailId) {
        await deleteFn(emailId);
        handleDeleteDialogClose();
      }
    },
    [deleteFn, emailId]
  );

  return (
    <div>
      <LightTooltip title={`Click to remove from the Contact's timeline.`}>
        <IconButton
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleDeleteDialogOpen}
          color={'primary'}
          disabled={!emailId}
        >
          <BookmarkOutlined />
        </IconButton>
      </LightTooltip>
      <ConfirmationDialog
        open={deleteDialogOpen}
        onClose={handleDeleteDialogClose}
        onConfirm={handleDelete}
        title="Remove Email"
        description={`Remove this Email from the Contact's timeline?`}
        confirmButtonText="Remove"
        cancelButtonText="Cancel"
      />
    </div>
  );
}
