import { SaveAlt } from '@mui/icons-material'; // Example icon
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { saveAs } from 'file-saver';
import React, { useState } from 'react';
import { EmailAttachmentRead } from 'types/api/deal/email';
import { dealService } from 'utils/axios';
import { getIconForContentType } from 'utils/file'; // Using file-saver library for cross-browser support

interface DownloadButtonProps {
  attachment: EmailAttachmentRead;
}

const DownloadAttachmentButton: React.FC<DownloadButtonProps> = ({ attachment }) => {
  const [loading, setLoading] = useState(false); // State to track if the file is being downloaded

  const handleDownload = async () => {
    setLoading(true); // Set loading to true when download starts
    try {
      // Make a GET request to the download endpoint
      const response = await dealService.get(`/download/email_attachment/${attachment.id}`, {
        responseType: 'blob' // Important for handling binary data
      });

      const fileName = attachment.file_name;

      // Use file-saver to trigger the download in the browser
      saveAs(response.data, fileName);
    } catch (error) {
      console.error('Error downloading the file:', error);
      alert('Failed to download the file.');
    } finally {
      setLoading(false); // Set loading to false when download completes (success or fail)
    }
  };

  return (
    <Button
      variant="outlined"
      startIcon={loading ? <CircularProgress size={16} /> : getIconForContentType(attachment.content_type) || <SaveAlt />}
      onClick={handleDownload}
      sx={{
        borderRadius: '20px',
        textTransform: 'none',
        padding: '6px 12px',
        minWidth: 'auto',
        borderColor: '#d3d3d3'
      }}
    >
      <Box display="flex" alignItems="center" maxWidth="150px">
        {/* You can adjust the maxWidth as needed */}
        <Typography
          variant="body2"
          sx={{
            color: '#5f6368',
            fontWeight: 400,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            maxWidth: '100%' // Ensures it stays within the parent Box width
          }}
        >
          {attachment.file_name}
        </Typography>
      </Box>
    </Button>
  );
};

export default DownloadAttachmentButton;
