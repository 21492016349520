export enum PermissionSet {
  deal_all_viewer = 'deal-all-viewer',
  deal_all_editor = 'deal-all-editor',
  deal_all_deleter = 'deal-all-deleter',
  deal_team_viewer = 'deal-team-viewer',
  deal_team_editor = 'deal-team-editor',
  deal_team_deleter = 'deal-team-deleter',
  deal_individual_viewer = 'deal-individual-viewer',
  deal_individual_editor = 'deal-individual-editor',
  deal_individual_deleter = 'deal-individual-deleter',
  contact_all_viewer = 'contact-all-viewer',
  contact_all_editor = 'contact-all-editor',
  contact_all_deleter = 'contact-all-deleter',
  contact_team_viewer = 'contact-team-viewer',
  contact_team_editor = 'contact-team-editor',
  contact_team_deleter = 'contact-team-deleter',
  contact_individual_viewer = 'contact-individual-viewer',
  contact_individual_editor = 'contact-individual-editor',
  contact_individual_deleter = 'contact-individual-deleter',
  company_all_viewer = 'company-all-viewer',
  company_all_editor = 'company-all-editor',
  company_all_deleter = 'company-all-deleter',
  company_team_viewer = 'company-team-viewer',
  company_team_editor = 'company-team-editor',
  company_team_deleter = 'company-team-deleter',
  company_individual_viewer = 'company-individual-viewer',
  company_individual_editor = 'company-individual-editor',
  company_individual_deleter = 'company-individual-deleter',
  organization_settings_editor = 'organization-settings-editor',
  organization_data_exporter = 'organization-data-exporter',
  email_all_viewer = 'email-all-viewer',
  email_all_deleter = 'email-all-deleter',
  email_individual_deleter = 'email-individual-deleter'
}

export const DEAL_READ_PERMISSIONS = [PermissionSet.deal_all_viewer, PermissionSet.deal_team_viewer, PermissionSet.deal_individual_viewer];

export const DEAL_EDIT_PERMISSIONS = [PermissionSet.deal_all_editor, PermissionSet.deal_team_editor, PermissionSet.deal_individual_editor];

export const DEAL_DELETE_PERMISSIONS = [
  PermissionSet.deal_all_deleter,
  PermissionSet.deal_team_deleter,
  PermissionSet.deal_individual_deleter
];

export const CONTACT_READ_PERMISSIONS = [
  PermissionSet.contact_all_viewer,
  PermissionSet.contact_team_viewer,
  PermissionSet.contact_individual_viewer
];

export const CONTACT_EDIT_PERMISSIONS = [
  PermissionSet.contact_all_editor,
  PermissionSet.contact_team_editor,
  PermissionSet.contact_individual_editor
];

export const CONTACT_DELETE_PERMISSIONS = [
  PermissionSet.contact_all_deleter,
  PermissionSet.contact_team_deleter,
  PermissionSet.contact_individual_deleter
];

export const COMPANY_READ_PERMISSIONS = [
  PermissionSet.company_all_viewer,
  PermissionSet.company_team_viewer,
  PermissionSet.company_individual_viewer
];

export const COMPANY_EDIT_PERMISSIONS = [
  PermissionSet.company_all_editor,
  PermissionSet.company_team_editor,
  PermissionSet.company_individual_editor
];

export const COMPANY_DELETE_PERMISSIONS = [
  PermissionSet.company_all_deleter,
  PermissionSet.company_team_deleter,
  PermissionSet.company_individual_deleter
];
