import CloseIcon from '@mui/icons-material/Close';
import dayjs from 'dayjs';
import { useCheckLicense } from 'hooks/useCheckLicense';
import React, { useState, useEffect } from 'react';
import { Alert, IconButton, Snackbar } from '@mui/material';
import { Product } from 'types/license';

const MobileAppBanner: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false);

  const hasProspectsLicense = useCheckLicense([Product.prospects]);

  useEffect(() => {
    // Check if the notification was dismissed within the past 7 days
    const dismissedDate = localStorage.getItem('notificationDismissedDate');

    if (!dismissedDate) {
      // If no dismissal date exists, show the banner
      setOpen(true);
    }
  }, []);

  const handleDismiss = () => {
    // Set the dismissal date to today
    const today = dayjs().toISOString();
    localStorage.setItem('notificationDismissedDate', today);
    setOpen(false);
  };

  return (
    <Snackbar open={hasProspectsLicense && open} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
      <Alert
        action={
          <IconButton aria-label="close" color="inherit" size="small" onClick={handleDismiss}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        sx={{
          mb: 2,
          boxShadow: '0px 4px 16px 4px rgba(0,0,0,0.1)',
          width: 400
        }}
        severity={'info'}
      >
        We've gone mobile! Download the <b>CRE OneSource</b> app today on the Apple App Store or Google Play Store.
      </Alert>
    </Snackbar>
  );
};

export default MobileAppBanner;
