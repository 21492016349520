import { Grid, Stack, Typography } from '@mui/material';
import { useUsersDisplay } from 'contexts/UserDisplayContext';
import dayjs from 'dayjs';
import _ from 'lodash';
import { getActivityPreviewFormat, getIconNodeFromActivityType } from 'pages/prospects/utils/activity';
import React from 'react';
import { ActivityRead } from 'types/api/deal/activity';
import { TimelineObject, TimelineObjectTypeEnum } from 'types/api/deal/contact_timeline';
import { EmailRead } from 'types/api/deal/email';
import { ActivityTypeEnum } from 'types/api/deal/enum';

export function TimelineObjectPrintView(props: { record: TimelineObject }) {
  const { record } = props;

  return record.type === TimelineObjectTypeEnum.email ? (
    <TimelineEmailPrintView record={record.metadata as EmailRead} />
  ) : (
    <ActivityTimelinePrintView record={record.metadata as ActivityRead} />
  );
}

function ActivityTimelinePrintView(props: { record: ActivityRead }) {
  const { record } = props;
  const usersDisplay = useUsersDisplay();

  const { datetime, ownerName, avatarContents, title, activityBody } = getActivityPreviewFormat(record, usersDisplay);

  return (
    <Grid container spacing={0} mt={1}>
      <Grid item xs={6}>
        <Typography fontSize={12} color={'textSecondary'}>
          {_.size(ownerName) > 0 ? ownerName : 'No Owner'}
        </Typography>
      </Grid>
      <Grid item xs={6} textAlign={'right'}>
        <Typography fontSize={12} color={'textSecondary'}>
          {dayjs(datetime).format('M/D/YYYY')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Stack spacing={1} direction="row" alignItems={'center'}>
          {avatarContents}
          <Typography fontWeight={500}>{title}</Typography>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Typography fontSize={12}>{activityBody}</Typography>
      </Grid>
    </Grid>
  );
}

function TimelineEmailPrintView(props: { record: EmailRead }) {
  const { record } = props;
  const avatarContents = getIconNodeFromActivityType(ActivityTypeEnum.email);

  return (
    <Grid container spacing={0} mt={1}>
      <Grid item xs={6}></Grid>
      <Grid item xs={6} textAlign={'right'}>
        <Typography fontSize={12} color={'textSecondary'}>
          {dayjs(record.created_at).format('M/D/YYYY')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Stack spacing={1} direction="row" alignItems={'center'}>
          {avatarContents}
          <Typography fontWeight={500}>{record.subject}</Typography>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Typography fontSize={12}>{record.body_preview}</Typography>
      </Grid>
    </Grid>
  );
}
