import { EmailRead } from 'types/api/deal/email';
import { OutlookEmailRead } from 'types/api/deal/outlook_email';
import { ActivityRead } from './activity';

export enum TimelineObjectTypeEnum {
  activity = 'activity',
  email = 'email',
  outlook_email = 'outlook_email'
}

export interface TimelineObject {
  id: number;
  subject: string;
  body_preview: string;
  date: string;
  type: TimelineObjectTypeEnum;
  metadata: ActivityRead | EmailRead | OutlookEmailRead;
}

export interface ContactTimeline {
  upcoming: TimelineObject[];
  completed: TimelineObject[];
}
