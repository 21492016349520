// material-ui
import { Grid } from '@mui/material';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import ToggleButtonHorizontal from 'pages/deal/components/ToggleButtonHorizontal';
import { DEAL_STATUS_OPTIONS } from 'pages/deal/constants/deal_enums';
import { DealMetadata } from 'constants/objectMetadata/dealMetadata';
import { getClosedDealFormIncomingChanges } from 'pages/deal/utils/deal_form';
import { useEffect } from 'react';
import { startEditingRecord } from 'store/reducers/record';
import { DealExtended } from 'types/api/deal/dealExtended';
import { DealStatus } from 'types/deal';
import { FormIdentifier } from 'types/record';
import { AdditionalChangeHandlerType, FieldComponentProps } from 'types/standardForm';

// ==============================|| TOGGLE BUTTON - VARIANT ||============================== //

export default function StatusToggleButton(props: FieldComponentProps) {
  const { fieldName } = props;
  const { setFieldValue, values } = useFormikContext<DealExtended>();

  const labelValue = _.get(values, DealMetadata.status.fieldName);

  const updateStatusOnChange: AdditionalChangeHandlerType = (value, setFieldValue) => {
    setFieldValue(DealMetadata.status.fieldName, value);

    const recordId = _.get(values, 'id');

    if (value === DealStatus.lost && recordId) {
      // ** NOTE: Duplicate logic found in DealMetadata.status.additionalChangeHandler. Be sure to make updates there and vice versa. **
      const incomingChanges = { ...values, status: DealStatus.lost, lost_date: new Date() };
      // Open edit dialog
      startEditingRecord(recordId, FormIdentifier.DealLostForm, incomingChanges);
    } else if (value === DealStatus.closed && recordId) {
      const incomingChanges = { ...values, ...getClosedDealFormIncomingChanges() };
      // Open edit dialog
      startEditingRecord(recordId, FormIdentifier.DealClosedForm, incomingChanges);
    }
  };

  useEffect(() => {
    if (!_.get(values, DealMetadata.status.fieldName))
      setFieldValue(DealMetadata.status.fieldName, _.find(DEAL_STATUS_OPTIONS, { key: labelValue })?.label);
  }, [labelValue, setFieldValue, values]);

  return (
    <Grid container rowSpacing={0.5} direction="column">
      <Grid item>
        <ToggleButtonHorizontal
          fieldName={fieldName}
          options={props.options ?? []}
          readOnly={false}
          additionalChangeHandler={updateStatusOnChange}
        />
      </Grid>
    </Grid>
  );
}
