import { Box } from '@mui/material';
import { DataDateTimeDisplay, DataUserDisplay } from 'components/form/basic/data_display';
import { DollarPercentToggle } from 'components/form/basic/DollarPercentToggle';
import StageStepper from 'components/form/basic/StageProgress';
import { BrokerPaymentCommissionInput } from 'components/form/compound/BrokerPaymentCommissionInput';
import { CommissionPaymentSection } from 'components/form/compound/CommissionPaymentSection';
import { DatePicker } from 'components/form/DatePicker';
import { DealTypeSelectOption } from 'components/form/DealTypeSelectOption';
import { ProbabilityOverrideField } from 'components/form/ProbabilityOverrideField';
import { SelectOption } from 'components/form/SelectOption';
import { formatDateForDisplay } from 'components/form/standard/utils/formatting';
import { TextFieldCurrency } from 'components/form/TextFieldCurrency';
import { TextFieldString, TextFieldStringMultiline } from 'components/form/TextFieldString';
import { TextFieldTwoDecimal, TextFieldWholeNumber } from 'components/form/TextFieldWholeNumber';

import {
  isFeatureEnabledOrgPreferences,
  isFieldRequiredOrgPreferences,
  isFieldVisibleOrgPreferences
} from 'contexts/DealOrgPreferencesContext';
import useAuth from 'hooks/useAuth';
import _ from 'lodash';
import KeyDatesArrayComponent from 'pages/deal/components/form/KeyDatesArrayComponent';
import GoogleMapsAutocomplete from 'pages/deal/components/GoogleMapsAutocomplete';
import { ContactLookupField } from 'pages/deal/components/LookupField';
import { DealOptionAutocompleteNew } from 'pages/deal/components/PlaintextAutocomplete';
import { SelectStageAsync } from 'pages/deal/components/SelectStageAsync';
import StatusToggleButton from 'pages/deal/components/StatusToggleButton';
import { SwitchField } from 'pages/deal/components/SwitchField';
import { getUSStateOptions } from 'pages/deal/constants/address';
import { DEAL_TYPE_OPTIONS, dealStatusDisplay, SIZE_TYPE_OPTIONS, sizeTypeDisplay } from 'pages/deal/constants/deal_enums';
import {
  dateSubmitHandler,
  dealTypeSelected,
  isClosed,
  isDealType,
  isDealViewPro,
  isDualAgency,
  isLost,
  isSharedDeal,
  keyDatesLoadHandler,
  keyDatesSubmitHandler,
  loadPercent,
  parseContactAsSimpleOption,
  parseDealOptionAsString,
  retrieveId,
  simpleOptionSubmitHandler,
  submitCurrency,
  submitPercent,
  submitTwoDecimal,
  useIsStageProbabilityEnabled
} from 'pages/deal/utils/deal_form';
import { formatCurrency, formatWholeNumber } from 'pages/deal/utils/reporting';
import { DealFeature, DealField, DealStatus, DealType, SizeType } from 'types/deal';
import { emptyKeyDateUpdate } from 'types/keyDate';
import { Product } from 'types/license';
import { ObjectMetadata } from 'types/standardForm';
import * as Yup from 'yup';

export const NOTES_PLAINTEXT_SCHEMA = Yup.string().max(25000, 'Notes must be less than 25,000 characters.').nullable();

const useInitialCommissionSplitValue = () => {
  const { user } = useAuth();
  return [
    {
      broker_name: { key: user?.user_id, label: `${user?.first_name} ${user?.last_name}` },
      role: 'executing_and_sourcing',
      estimate_percent: 1,
      actual_percent: 1 // Needs to be 1 to make sure calculations are done correctly
    }
  ];
};

export const DealMetadata: ObjectMetadata = {
  _address_search: {
    fieldName: '_address_search',
    products: [Product.deal_view_pro],
    displayName: 'Address search',
    initialValue: null,
    visibility: (_, dealOrgPreferences) => isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.ADDRESS),
    _schema: Yup.object().nullable(),
    component: GoogleMapsAutocomplete,
    virtual: true,
    additionalChangeHandler: (value, setFieldValue) => {
      if (_.get(value, 'city')) {
        setFieldValue('address_line_1', _.get(value, 'line_1'));
        setFieldValue('address_line_2', _.get(value, 'line_2'));
        setFieldValue('address_city', _.get(value, 'city'));
        setFieldValue('address_state', _.get(value, 'state'));
        setFieldValue('address_postal_code', _.get(value, 'postal_code'));
        setFieldValue('address_country', _.get(value, 'country'));
        setFieldValue('address_latitude', _.get(value, 'latitude'));
        setFieldValue('address_longitude', _.get(value, 'longitude'));
      }
    }
  },
  commission_payments_percent_toggle: {
    fieldName: 'commission_payments_percent_toggle',
    products: [Product.deal_view_pro],
    displayName: 'Commission Payment Date Breakdown',
    initialValue: true,
    _schema: Yup.boolean().nullable(),
    component: DollarPercentToggle,
    visibility: (values) => isClosed(values)
  },
  commission_split_percent_toggle: {
    fieldName: 'commission_split_percent_toggle',
    products: [Product.deal_view_pro],
    displayName: 'Commission Split',
    initialValue: true,
    _schema: Yup.boolean().nullable(),
    component: DollarPercentToggle
  },
  commission_percent_toggle: {
    fieldName: 'commission_percent_toggle',
    products: [Product.deal_view_pro],
    displayName: 'Commission',
    initialValue: false,
    _schema: Yup.boolean().nullable(),
    component: DollarPercentToggle
  },
  _deal_category: {
    fieldName: '_deal_category',
    products: [Product.deal_view_pro],
    displayName: '',
    initialValue: null,
    _schema: Yup.string().nullable(),
    component: TextFieldString,
    virtual: true
  },
  shared_deal: {
    fieldName: 'shared_deal',
    products: [Product.deal_view_pro],
    displayName: 'Is this client being shared with another broker?',
    initialValue: false,
    visibility: (_, dealOrgPreferences) => isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.SHARED_BROKER),
    _schema: Yup.boolean().nullable(),
    component: SwitchField,
    additionalChangeHandler: (value: any, setFieldValue: any) => {
      if (!value) {
        setFieldValue('shared_broker_company', null);
        setFieldValue('shared_broker', null);
      }
    }
  },
  is_dual_agency: {
    fieldName: 'is_dual_agency',
    products: [Product.deal_view_pro],
    displayName: 'Is there an Outside Broker on this deal?',
    initialValue: false,
    visibility: (_, dealOrgPreferences) => isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.OUTSIDE_BROKER),
    _schema: Yup.boolean().nullable(),
    component: SwitchField,
    additionalChangeHandler: (value: any, setFieldValue: any) => {
      if (!value) {
        setFieldValue('counterparty_broker_company', null);
        setFieldValue('outside_broker', null);
      }
    }
  },
  probability_override: {
    fieldName: 'probability_override',
    products: [Product.deal_view_pro],
    displayName: 'Probability',
    initialValue: null,
    _schema: Yup.number().nullable().positive('Probability must be greater than 0%.').max(100, 'Probability must not exceed 100%'),
    component: ProbabilityOverrideField,
    submitHandler: submitPercent,
    loadHandler: loadPercent,
    visibility: useIsStageProbabilityEnabled
  },
  address_city: {
    fieldName: 'address_city',
    products: [Product.deal_view_pro],
    displayName: 'City',
    initialValue: null,
    visibility: (_, dealOrgPreferences) => isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.ADDRESS),
    _schema: Yup.string().max(255).nullable(),
    component: TextFieldString
  },
  address_country: {
    fieldName: 'address_country',
    products: [Product.deal_view_pro],
    displayName: 'Country',
    initialValue: null,
    visibility: (_, dealOrgPreferences) => isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.ADDRESS),
    _schema: Yup.string().max(255).nullable(),
    component: TextFieldString
  },
  address_latitude: {
    fieldName: 'address_latitude',
    products: [Product.deal_view_pro],
    displayName: 'Latitude',
    visibility: () => false,
    initialValue: null,
    _schema: Yup.number().nullable(),
    component: TextFieldString
  },
  address_line_1: {
    fieldName: 'address_line_1',
    products: [Product.deal_view_pro],
    displayName: 'Line 1',
    initialValue: null,
    visibility: (_, dealOrgPreferences) => isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.ADDRESS),
    _schema: (dealOrgPreferences) =>
      Yup.string()
        .max(255)
        .nullable()
        .when([], {
          is: () => isFieldRequiredOrgPreferences(dealOrgPreferences, DealField.ADDRESS),
          then: (schema) => schema.required('Address is required.')
        }),
    component: TextFieldString
  },
  address_line_2: {
    fieldName: 'address_line_2',
    products: [Product.deal_view_pro],
    displayName: 'Line 2',
    initialValue: null,
    visibility: (_, dealOrgPreferences) => isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.ADDRESS),
    _schema: Yup.string().max(255).nullable(),
    component: TextFieldString
  },
  address_longitude: {
    fieldName: 'address_longitude',
    products: [Product.deal_view_pro],
    displayName: 'Longitude',
    visibility: () => false,
    initialValue: null,
    _schema: Yup.number().nullable(),
    component: TextFieldString
  },
  address_postal_code: {
    fieldName: 'address_postal_code',
    products: [Product.deal_view_pro],
    displayName: 'Zip',
    initialValue: null,
    visibility: (_, dealOrgPreferences) => isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.ADDRESS),
    _schema: Yup.string().max(255).nullable(),
    component: TextFieldString
  },
  address_state: {
    fieldName: 'address_state',
    products: [Product.deal_view_pro],
    displayName: 'State',
    initialValue: null,
    _schema: Yup.string().max(2).nullable(),
    component: SelectOption,
    visibility: (_, dealOrgPreferences) => isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.ADDRESS),
    options: getUSStateOptions()
  },
  asset_type: {
    fieldName: 'asset_type',
    products: [Product.deal_view_pro, Product.deal_view],
    displayName: 'Asset Type',
    initialValue: null,
    _schema: Yup.string().required(`Asset Type is required.`),
    component: DealOptionAutocompleteNew,
    loadHandler: parseDealOptionAsString,
    fieldCorrespondence: 'asset_type_name',
    additionalChangeHandler: (value: string, setFieldValue, prevValue, values) => {
      // If no values have been entered for Size (est or actual), autofill the Size Type field
      const textValue = value;
      if (_.isNil(_.get(values, 'size_est')) && _.isNil(_.get(values, 'size'))) {
        if (['Land'].includes(textValue)) {
          setFieldValue('size_type', SizeType.acres);
        } else if (['Multifamily', 'Senior / Assisted Living'].includes(textValue)) {
          setFieldValue('size_type', SizeType.units);
        } else {
          setFieldValue('size_type', SizeType.square_feet);
        }
      }
    }
  },
  client_industry: {
    fieldName: 'client_industry',
    products: [Product.deal_view_pro, Product.deal_view],
    displayName: 'Client Industry',
    initialValue: null,
    visibility: (_, dealOrgPreferences) => isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.CLIENT_INDUSTRY),
    _schema: (dealOrgPreferences) =>
      Yup.string()
        .nullable()
        .when([], {
          is: () => isFieldRequiredOrgPreferences(dealOrgPreferences, DealField.CLIENT_INDUSTRY),
          then: (schema) => schema.required('Client Industry is required.')
        }),
    component: DealOptionAutocompleteNew,
    loadHandler: parseDealOptionAsString,
    fieldCorrespondence: 'client_industry_name'
  },
  contact: {
    fieldName: 'contact',
    products: [Product.deal_view_pro, Product.deal_view],
    displayName: 'Primary Contact',
    initialValue: null,
    visibility: (_, dealOrgPreferences) => isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.CONTACT),
    _schema: (dealOrgPreferences) =>
      Yup.object()
        .nullable()
        .when([], {
          is: () => isFieldRequiredOrgPreferences(dealOrgPreferences, DealField.CONTACT),
          then: (schema) => schema.required('Primary Contact is required.')
        }),
    component: ContactLookupField,
    submitHandler: simpleOptionSubmitHandler,
    loadHandler: parseContactAsSimpleOption,
    fieldCorrespondence: 'contact_id'
  },
  outside_broker: {
    fieldName: 'outside_broker',
    products: [Product.deal_view_pro],
    displayName: 'Outside Broker Name',
    disabled: (values) => !isDualAgency(values),
    initialValue: null,
    visibility: (_, dealOrgPreferences) => isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.OUTSIDE_BROKER),
    _schema: Yup.object().nullable(),
    component: ContactLookupField,
    submitHandler: simpleOptionSubmitHandler,
    loadHandler: parseContactAsSimpleOption,
    fieldCorrespondence: 'outside_broker_id'
  },
  shared_broker: {
    fieldName: 'shared_broker',
    products: [Product.deal_view_pro],
    displayName: 'Shared Broker Name',
    disabled: (values) => !isSharedDeal(values),
    initialValue: null,
    visibility: (_, dealOrgPreferences) => isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.SHARED_BROKER),
    _schema: Yup.object().nullable(),
    component: ContactLookupField,
    submitHandler: simpleOptionSubmitHandler,
    loadHandler: parseContactAsSimpleOption,
    fieldCorrespondence: 'shared_broker_id'
  },
  close_date: {
    fieldName: 'close_date',
    products: [Product.deal_view_pro, Product.deal_view],
    displayName: 'Actual Close Date',
    disabled: (values) => !isClosed(values),
    initialValue: null,
    _schema: Yup.date()
      .nullable()
      .typeError(`Close Date must be a date.`)
      .when('status', { is: DealStatus.closed, then: (schema) => schema.required('Close Date is required.') }),
    component: DatePicker,
    submitHandler: dateSubmitHandler,
    formatForDisplay: formatDateForDisplay
  },
  close_date_est: {
    fieldName: 'close_date_est',
    products: [Product.deal_view_pro, Product.deal_view],
    displayName: 'Estimated Close Date',
    initialValue: null,
    _schema: Yup.date()
      .nullable()
      .typeError(`Estimated Close Date must be a date.`)
      .when('status', {
        is: (x: DealStatus) => x !== DealStatus.closed,
        then: (schema) => schema.required(`Estimated Close Date is required.`)
      }),
    component: DatePicker,
    submitHandler: dateSubmitHandler,
    formatForDisplay: formatDateForDisplay
  },
  commission: {
    fieldName: 'commission',
    products: [Product.deal_view_pro, Product.deal_view],
    displayName: 'Actual Commission',
    disabled: (values) => !isClosed(values),
    initialValue: null,
    _schema: Yup.number()
      .min(0, 'Commission must be positive.')
      .nullable()
      .typeError(`A value must be entered for Commission.`)
      .when('status', { is: DealStatus.closed, then: (schema) => schema.required('Commission is required.') }),
    component: TextFieldCurrency,
    submitHandler: submitCurrency,
    formatForDisplay: formatCurrency
  },
  commission_est: {
    fieldName: 'commission_est',
    products: [Product.deal_view_pro, Product.deal_view],
    displayName: 'Estimated Commission',
    initialValue: null,
    _schema: Yup.number()
      .nullable()
      .min(0, 'Estimated Commission must be positive.')
      .typeError(`A number is expected for Estimated Commission.`)
      .when('status', {
        is: (x: DealStatus) => x !== DealStatus.closed,
        then: (schema) => schema.required(`Estimated Commission is required.`)
      }),
    component: TextFieldCurrency,
    submitHandler: submitCurrency,
    formatForDisplay: formatCurrency
  },
  _commission_est_percent_toggle: {
    fieldName: '_commission_est_percent_toggle',
    products: [Product.deal_view_pro],
    displayName: '',
    initialValue: false,
    _schema: Yup.boolean().nullable(),
    component: DollarPercentToggle,
    virtual: true
  },
  counterparty_broker_company: {
    fieldName: 'counterparty_broker_company',
    products: [Product.deal_view_pro],
    displayName: 'Outside Brokerage Company',
    disabled: (values) => !isDualAgency(values),
    initialValue: null,
    visibility: (_, dealOrgPreferences) => isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.OUTSIDE_BROKER),
    _schema: Yup.string().nullable(),
    component: DealOptionAutocompleteNew,
    loadHandler: parseDealOptionAsString,
    fieldCorrespondence: 'counterparty_broker_company_name'
  },
  deal_type: {
    fieldName: 'deal_type',
    products: [Product.deal_view_pro, Product.deal_view],
    displayName: 'Deal Type',
    options: DEAL_TYPE_OPTIONS,
    initialValue: null,
    _schema: Yup.string().required(`Deal Type is required.`),
    component: DealTypeSelectOption
  },
  hire_date: {
    fieldName: 'hire_date',
    products: [Product.deal_view_pro, Product.deal_view],
    displayName: 'Hire Date',
    initialValue: null,
    visibility: (_, dealOrgPreferences) => isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.HIRE_DATE),
    _schema: (dealOrgPreferences) =>
      Yup.date()
        .nullable()
        .typeError(`Hire Date must be a date.`)
        .when([], {
          is: () => isFieldRequiredOrgPreferences(dealOrgPreferences, DealField.HIRE_DATE),
          then: (schema) => schema.required('Hire Date is required.')
        }),
    component: DatePicker,
    submitHandler: dateSubmitHandler,
    formatForDisplay: formatDateForDisplay
  },
  key_dates: {
    fieldName: 'key_dates',
    fieldCorrespondence: 'key_dates_input',
    products: [Product.deal_view_pro],
    displayName: 'Key Dates',
    initialValue: () => [emptyKeyDateUpdate],
    _schema: Yup.array().of(
      Yup.object().shape(
        {
          id: Yup.number().nullable(),
          is_delete: Yup.boolean().nullable(),
          date_type: Yup.string()
            .nullable()
            .when(['date', 'is_delete'], {
              is: (date: any, is_delete: boolean) => !is_delete && !!date,
              then: (schema) => schema.required('Date Type is required.'),
              otherwise: (schema) => schema
            }),
          date: Yup.date()
            .nullable()
            .typeError('Date is required.')
            .when(['date_type', 'is_delete'], {
              is: (date_type: any, is_delete: boolean) => !is_delete && !!date_type,
              then: (schema) => schema.required('Date is required.'),
              otherwise: (schema) => schema
            })
        },
        [['date_type', 'date']]
      )
    ),
    component: KeyDatesArrayComponent,
    submitHandler: keyDatesSubmitHandler,
    loadHandler: keyDatesLoadHandler
  },
  lease_expiration: {
    fieldName: 'lease_expiration',
    products: [Product.deal_view_pro],
    displayName: 'Lease Expiration',
    visibility: (values, dealOrgPreferences) =>
      isDealType(values, [DealType.landlord_agency, DealType.tenant_representation, DealType.lease_both]) &&
      isClosed(values) &&
      isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.LEASE_EXPIRATION),
    initialValue: null,
    _schema: (dealOrgPreferences) =>
      Yup.date()
        .nullable()
        .typeError(`Lease Expiration must be a date.`)
        .when(['status', 'deal_type'], {
          is: (status: DealStatus, dealType: DealType) =>
            [DealType.landlord_agency, DealType.tenant_representation, DealType.lease_both].includes(dealType) &&
            status === DealStatus.closed &&
            isFieldRequiredOrgPreferences(dealOrgPreferences, DealField.LEASE_EXPIRATION),
          then: (schema) => schema.required('Lease Expiration is required.')
        }),
    component: DatePicker,
    submitHandler: dateSubmitHandler,
    disabled: (values) => !isClosed(values),
    formatForDisplay: formatDateForDisplay
  },
  lost_date: {
    fieldName: 'lost_date',
    products: [Product.deal_view_pro, Product.deal_view],
    displayName: 'Lost Date',
    disabled: (values) => !isLost(values),
    initialValue: null,
    _schema: Yup.date()
      .nullable()
      .typeError(`Lost Date must be a date.`)
      .when('status', { is: DealStatus.lost, then: (schema) => schema.required('Lost Date is required.') }),
    component: DatePicker,
    submitHandler: dateSubmitHandler,
    formatForDisplay: formatDateForDisplay
  },
  lost_reason: {
    fieldName: 'lost_reason',
    products: [Product.deal_view_pro, Product.deal_view],
    displayName: 'Lost Reason',
    disabled: (values) => !isLost(values),
    initialValue: null,
    _schema: Yup.string()
      .nullable()
      .when('status', { is: DealStatus.lost, then: (schema) => schema.required('Lost Reason is required.') }),
    component: DealOptionAutocompleteNew,
    loadHandler: parseDealOptionAsString,
    fieldCorrespondence: 'lost_reason_name'
  },
  move_in_date: {
    fieldName: 'move_in_date',
    products: [Product.deal_view_pro, Product.deal_view],
    displayName: 'Actual Move In Date',
    disabled: (values) => !isClosed(values),
    visibility: (values, dealOrgPreferences) =>
      isDealType(values, [DealType.landlord_agency, DealType.lease_both]) &&
      isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.MOVE_IN_DATE),
    initialValue: null,
    _schema: Yup.date().nullable().typeError(`Move In Date must be a date.`),
    component: DatePicker,
    submitHandler: dateSubmitHandler,
    formatForDisplay: formatDateForDisplay
  },
  move_in_date_est: {
    fieldName: 'move_in_date_est',
    products: [Product.deal_view_pro, Product.deal_view],
    displayName: 'Estimated Move In Date',
    visibility: (values, dealOrgPreferences) =>
      isDealType(values, [DealType.landlord_agency, DealType.lease_both]) &&
      isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.MOVE_IN_DATE),
    initialValue: null,
    _schema: Yup.date().nullable().typeError(`Estimated Move In Date must be a date.`),
    component: DatePicker,
    submitHandler: dateSubmitHandler,
    formatForDisplay: formatDateForDisplay
  },
  name: {
    fieldName: 'name',
    products: [Product.deal_view_pro, Product.deal_view],
    displayName: 'Deal Name',
    initialValue: null,
    _schema: Yup.string().max(255, 'Deal Name must be less than 256 characters.').required(`Deal Name is required.`),
    component: TextFieldString,
    componentProps: {
      tooltip: 'An example of Deal Name is Tenant A - Main Street - Suite B'
    }
  },
  notes_plaintext: {
    fieldName: 'notes_plaintext',
    displayName: '',
    initialValue: '',
    _schema: NOTES_PLAINTEXT_SCHEMA,
    component: TextFieldStringMultiline
  },
  property_name: {
    fieldName: 'property_name',
    products: [Product.deal_view_pro],
    displayName: 'Property Name',
    visibility: (values, dealOrgPreferences) => isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.PROPERTY_NAME),
    initialValue: null,
    _schema: (dealOrgPreferences) =>
      Yup.string()
        .nullable()
        .when('deal_type', {
          is: (x: DealType) => isFieldRequiredOrgPreferences(dealOrgPreferences, DealField.PROPERTY_NAME),
          then: (schema) => schema.required('Property Name is required.')
        }),
    component: DealOptionAutocompleteNew,
    loadHandler: parseDealOptionAsString,
    fieldCorrespondence: 'property_name_name'
  },
  relocation_date: {
    fieldName: 'relocation_date',
    products: [Product.deal_view_pro, Product.deal_view],
    displayName: 'Actual Relocation Date',
    visibility: (values, dealOrgPreferences) =>
      isDealType(values, [DealType.tenant_representation, DealType.lease_both]) &&
      isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.RELOCATION_DATE),
    disabled: (values) => !isClosed(values),
    initialValue: null,
    _schema: Yup.date().nullable().typeError(`Relocation Date must be a date.`),
    component: DatePicker,
    submitHandler: dateSubmitHandler,
    formatForDisplay: formatDateForDisplay
  },
  relocation_date_est: {
    fieldName: 'relocation_date_est',
    products: [Product.deal_view_pro, Product.deal_view],
    displayName: 'Estimated Relocation Date',
    visibility: (values, dealOrgPreferences) =>
      isDealType(values, [DealType.tenant_representation, DealType.lease_both]) &&
      isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.RELOCATION_DATE),
    initialValue: null,
    _schema: Yup.date().nullable().typeError(`Estimated Relocation Date must be a date.`),
    component: DatePicker,
    submitHandler: dateSubmitHandler,
    formatForDisplay: formatDateForDisplay
  },
  shared_broker_company: {
    fieldName: 'shared_broker_company',
    products: [Product.deal_view_pro],
    displayName: 'Shared Brokerage Company',
    disabled: (values) => !isSharedDeal(values),
    initialValue: null,
    visibility: (_, dealOrgPreferences) => isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.SHARED_BROKER),
    _schema: Yup.string().nullable(),
    component: DealOptionAutocompleteNew,
    loadHandler: parseDealOptionAsString,
    fieldCorrespondence: 'shared_broker_company_name'
  },
  size: {
    fieldName: 'size',
    products: [Product.deal_view_pro, Product.deal_view],
    displayName: 'Actual Size',
    disabled: (values) => !isClosed(values),
    initialValue: null,
    visibility: (_, dealOrgPreferences) => isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.SIZE),
    _schema: (dealOrgPreferences) =>
      Yup.number()
        .nullable()
        .min(0, 'Actual Size must be positive.')
        .when('status', {
          is: (x: DealStatus) => x === DealStatus.closed && isFieldRequiredOrgPreferences(dealOrgPreferences, DealField.SIZE),
          then: (schema) => schema.typeError('Actual Size must be a number.').required(`Actual Size must be a number.`)
        }),
    component: TextFieldTwoDecimal,
    submitHandler: submitTwoDecimal,
    formatForDisplay: formatWholeNumber
  },
  size_est: {
    fieldName: 'size_est',
    products: [Product.deal_view_pro, Product.deal_view],
    displayName: 'Estimated Size',
    initialValue: null,
    visibility: (_, dealOrgPreferences) => isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.SIZE),
    _schema: (dealOrgPreferences) =>
      Yup.number()
        .nullable()
        .min(0, 'Estimated Size must be positive.')
        .when('status', {
          is: (x: DealStatus) => x === DealStatus.open && isFieldRequiredOrgPreferences(dealOrgPreferences, DealField.SIZE),
          then: (schema) => schema.typeError('Estimated Size must be a number.').required(`Estimated Size must be a number.`)
        }),
    component: TextFieldTwoDecimal,
    submitHandler: submitTwoDecimal,
    formatForDisplay: formatWholeNumber
  },
  size_type: {
    fieldName: 'size_type',
    products: [Product.deal_view_pro, Product.deal_view],
    displayName: '',
    options: SIZE_TYPE_OPTIONS,
    initialValue: SizeType.square_feet,
    visibility: (_, dealOrgPreferences) => isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.SIZE),
    _schema: (dealOrgPreferences) =>
      Yup.string()
        .nullable()
        .when([], {
          is: () => isFieldRequiredOrgPreferences(dealOrgPreferences, DealField.SIZE),
          then: (schema) => schema.required('Choose a measurement type for the property size.')
        }),
    component: SelectOption,
    formatForDisplay: (x) => _.get(sizeTypeDisplay, x, '')
  },
  source_type: {
    fieldName: 'source_type',
    products: [Product.deal_view_pro, Product.deal_view],
    displayName: 'Deal Source',
    initialValue: null,
    visibility: (_, dealOrgPreferences) => isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.SOURCE_TYPE),
    _schema: (dealOrgPreferences) =>
      Yup.string()
        .nullable()
        .when([], {
          is: () => isFieldRequiredOrgPreferences(dealOrgPreferences, DealField.SOURCE_TYPE),
          then: (schema) => schema.required('Deal Source is required.')
        }),
    component: DealOptionAutocompleteNew,
    loadHandler: parseDealOptionAsString,
    fieldCorrespondence: 'source_type_name'
  },
  square_feet: {
    fieldName: 'square_feet',
    products: [Product.deal_view_pro],
    displayName: 'Square Feet',
    disabled: (values) => !isClosed(values),
    initialValue: null,
    _schema: Yup.number().min(0, 'Square Feet must be positive.').nullable().typeError(`Square Feet must be a number.`),
    component: TextFieldWholeNumber
  },
  square_feet_est: {
    fieldName: 'square_feet_est',
    products: [Product.deal_view_pro],
    displayName: 'Estimated Square Feet',
    initialValue: null,
    _schema: Yup.number().min(0, 'Estimated Square Feet must be positive.').nullable().typeError(`Estimated Square Feet must be a number.`),
    component: TextFieldWholeNumber
  },
  stage: {
    fieldName: 'stage',
    products: [Product.deal_view_pro],
    displayName: 'Stage',
    initialValue: null,
    visibility: (_, dealOrgPreferences) => isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.STAGE),
    _schema: (dealOrgPreferences) =>
      Yup.object()
        .nullable()
        .when([], {
          is: () => isFieldRequiredOrgPreferences(dealOrgPreferences, DealField.STAGE),
          then: (schema) => schema.required('Stage is required.')
        }),
    component: SelectStageAsync,
    submitHandler: retrieveId,
    fieldCorrespondence: 'stage_id',
    disabled: (values) => !dealTypeSelected(values)
  },
  status: {
    fieldName: 'status',
    products: [Product.deal_view_pro, Product.deal_view],
    displayName: 'Status',
    options: _.map(dealStatusDisplay, (val, idx) => ({ key: idx, label: val })),
    initialValue: DealStatus.open,
    _schema: Yup.string().required(`Status is required.`).nullable(),
    component: StatusToggleButton,
    additionalChangeHandler: (value, setFieldValue, prevValue) => {
      if (value === DealStatus.lost) {
        setFieldValue('lost_date', new Date());
      } else if (value === DealStatus.open && prevValue === DealStatus.lost) {
        setFieldValue('lost_date', null);
      }
    }
  },
  submarket: {
    fieldName: 'submarket',
    products: [Product.deal_view_pro],
    displayName: 'Submarket',
    initialValue: null,
    visibility: (_, dealOrgPreferences) => isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.SUBMARKET),
    _schema: (dealOrgPreferences) =>
      Yup.string()
        .nullable()
        .when([], {
          is: () => isFieldRequiredOrgPreferences(dealOrgPreferences, DealField.SUBMARKET),
          then: (schema) => schema.required('Submarket is required.')
        }),
    component: DealOptionAutocompleteNew,
    loadHandler: parseDealOptionAsString,
    fieldCorrespondence: 'submarket_name'
  },
  tenant_name: {
    fieldName: 'tenant_name',
    products: [Product.deal_view_pro],
    displayName: 'Tenant Name',
    visibility: (values, dealOrgPreferences) =>
      isDealType(values, [DealType.landlord_agency, DealType.tenant_representation, DealType.lease_both]) &&
      isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.TENANT_NAME),
    initialValue: null,
    _schema: (dealOrgPreferences) =>
      Yup.string()
        .nullable()
        .when('deal_type', {
          is: (x: DealType) =>
            x === DealType.tenant_representation &&
            isDealViewPro() &&
            isFieldRequiredOrgPreferences(dealOrgPreferences, DealField.TENANT_NAME),
          then: (schema) => schema.required('Tenant Name is required.')
        }),
    component: DealOptionAutocompleteNew,
    loadHandler: parseDealOptionAsString,
    fieldCorrespondence: 'tenant_name_name'
  },
  transaction_value: {
    fieldName: 'transaction_value',
    displayName: 'Actual Transaction Value',
    initialValue: null,
    _schema: Yup.number()
      .min(0, 'Actual Transaction Value must be positive.')
      .nullable()
      .typeError(`Actual Transaction Value must be a number.`)
      .when(['status'], {
        is: (status: DealStatus) => status === DealStatus.closed,
        then: (schema) => schema.required('Actual Transaction Value is required.')
      }),
    component: TextFieldCurrency,
    submitHandler: submitCurrency,
    formatForDisplay: formatCurrency
  },
  transaction_value_est: {
    fieldName: 'transaction_value_est',
    displayName: 'Estimated Transaction Value',
    initialValue: null,
    _schema: Yup.number()
      .min(0, 'Estimated Transaction Value must be positive.')
      .nullable()
      .typeError(`Estimated Transaction Value must be a number.`)
      .when(['status'], {
        is: (status: DealStatus) => status !== DealStatus.closed,
        then: (schema) => schema.required('Estimated Transaction Value is required.')
      }),
    component: TextFieldCurrency,
    submitHandler: submitCurrency,
    formatForDisplay: formatCurrency
  },
  broker_payment_commission: {
    fieldName: 'broker_payment_commission',
    products: [Product.deal_view_pro],
    displayName: 'Broker Split',
    visibility: (_, dealOrgPreferences) => isFeatureEnabledOrgPreferences(dealOrgPreferences, DealFeature.COMMISSION_SPLITS),
    initialValue: useInitialCommissionSplitValue,
    _schema: Yup.array()
      .of(
        Yup.object().shape({
          broker_name: Yup.object().required('Broker name must be provided.'),
          role: Yup.string().nullable(),
          estimate_percent: Yup.number()
            .nullable()
            .when(['status'], {
              is: (status: DealStatus) => status !== DealStatus.closed,
              then: (schema) => schema.min(0, 'Estimated Split must be positive.').max(1, 'Estimated Split must be less than 100%.')
            }),
          actual_percent: Yup.number()
            .nullable()
            .when(['status'], {
              is: (status: DealStatus) => status === DealStatus.closed,
              then: (schema) => schema.min(0, 'Actual Split must be positive.').max(1, 'Actual Split must be less than 100%.')
            })
        })
      )
      .test('estimate-percent-total-100', 'estimate-percent-total-100', (items) => {
        const total = _.sumBy(items, (item) => item?.estimate_percent || 0);
        return total === 1;
      })
      .test('actual-percent-total-100', 'actual-percent-total-100', (items, testContext) => {
        // Only enforce this test if the status is Closed
        const _isClosed = _.get(testContext, 'parent.status', DealStatus.open) === DealStatus.closed;
        const total = _.sumBy(items, (item) => item?.actual_percent || 0);
        return !_isClosed || total === 1;
      }),
    component: BrokerPaymentCommissionInput,
    loadHandler: (x, userOptionsDictionary: Record<string, string>) =>
      _.map(x, (y) => ({
        ...y,
        broker_name: _.isNil(y.broker_name)
          ? null
          : {
              key: y.broker_name,
              label: _.get(userOptionsDictionary, y.broker_name, 'Unknown')
            }
      })),
    submitHandler: (x) => _.map(x, (y) => ({ ...y, broker_name: y.broker_name.key }))
  },
  commission_payments: {
    fieldName: 'commission_payments',
    displayName: 'Commission Payments',
    initialValue: [
      { amount: null, percent: null, date: null, paid: false },
      { amount: null, percent: null, date: null, paid: false }
    ],
    _schema: (dealOrgPreferences) =>
      Yup.array().when(['status'], {
        is: (status: DealStatus) =>
          status === DealStatus.closed && isFeatureEnabledOrgPreferences(dealOrgPreferences, DealFeature.COMMISSION_PAYMENTS),
        then: (schema) =>
          schema
            .of(
              Yup.object().shape({
                amount: Yup.number().nullable().min(0, 'Amount must be positive.'),
                date: Yup.date()
                  .nullable()
                  .when(['amount', 'percent'], {
                    is: (amount: number | null, percent: number | null) =>
                      (!_.isNil(amount) && amount > 0) || (!_.isNil(percent) && percent > 0),
                    then: (schema) => schema.required('Date Due is required when Amount Due is provided.')
                  }),
                paid: Yup.boolean().nullable(),
                percent: Yup.number().nullable().min(0, 'Percent must be at least 0%.').max(1, 'Percent must be less than 100%.')
              })
            )
            .test('first-item-amount-required', 'First Amount Due is required', function (value, testContext) {
              const isPercent = _.get(testContext, 'parent.commission_payments_percent_toggle', false);
              if (value && value.length > 0) {
                if (isPercent) {
                  return value[0].percent !== undefined && value[0].percent !== null;
                } else {
                  return value[0].amount !== undefined && value[0].amount !== null;
                }
              }
              return true;
            })
            .test('amount-total-100', 'amount-total-100', (items, testContext) => {
              // Only enforce this test if the field is set to Amount
              const isPercent = _.get(testContext, 'parent.commission_payments_percent_toggle', false);
              const commission = _.get(testContext, 'parent.commission', null);

              if (isPercent) {
                const total = _.sumBy(items, (item) => item?.percent || 0);
                return total === 1;
              } else {
                const total = _.sumBy(items, (item) => item?.amount || 0);
                return total === commission;
              }
            })
      }),
    component: CommissionPaymentSection,
    visibility: (values, dealOrgPreferences) =>
      isClosed(values) && isFeatureEnabledOrgPreferences(dealOrgPreferences, DealFeature.COMMISSION_PAYMENTS),
    submitHandler: (value) =>
      _.map(value, (x) => ({
        ...x,
        amount: submitCurrency(x.amount),
        date: dateSubmitHandler(x.date)
      }))
  },
  break: {
    fieldName: 'Break',
    displayName: 'Break',
    initialValue: null,
    _schema: Yup.object().nullable(),
    component: () => <Box sx={{ width: '100%' }}></Box>,
    virtual: true
  },
  stage_stepper: {
    fieldName: 'stage_stepper',
    products: [Product.deal_view_pro, Product.deal_view],
    displayName: '',
    initialValue: null,
    _schema: Yup.number().nullable(),
    component: StageStepper,
    virtual: true
  },
  created_at: {
    fieldName: 'created_at',
    displayName: 'Date Created',
    initialValue: null,
    _schema: Yup.date().nullable(),
    component: DataDateTimeDisplay,
    virtual: true
  },
  updated_at: {
    fieldName: 'updated_at',
    displayName: 'Date Modified',
    initialValue: null,
    _schema: Yup.date().nullable(),
    component: DataDateTimeDisplay,
    virtual: true
  },
  created_by: {
    fieldName: 'created_by',
    displayName: 'Created By',
    initialValue: null,
    _schema: Yup.string().nullable(),
    component: DataUserDisplay,
    virtual: true
  },
  updated_by: {
    fieldName: 'updated_by',
    displayName: 'Modified By',
    initialValue: null,
    _schema: Yup.string().nullable(),
    component: DataUserDisplay,
    virtual: true
  }
};
