import { OutlookLogoIcon } from '@fluentui/react-icons-mdl2-branded';
import { BookmarkAddOutlined, LockOutlined } from '@mui/icons-material';
import { Box, CircularProgress, Grid, IconButton, Stack, Typography } from '@mui/material';
import * as Sentry from '@sentry/react';
import DownloadAttachmentButton from 'components/form/forms/sections/ActivityTimeline/Email/DownloadAttachmentButton';
import EmailActionMenu from 'components/form/forms/sections/ActivityTimeline/Email/EmailActionMenu';
import { TimelineEntry } from 'components/form/forms/sections/ActivityTimeline/TimelineEntry';
import dayjs from 'dayjs';
import _ from 'lodash';
import { LightTooltip } from 'pages/deal/components/InfoTooltip';
import { getBaseQueryOptionsDealServiceV2 } from 'pages/deal/utils/api';
import { formatDateString, formatDateTimeString } from 'pages/deal/utils/reporting';
import { getIconNodeFromActivityType } from 'pages/prospects/utils/activity';
import React, { ReactNode, useMemo, useState } from 'react';
import { useQuery, UseQueryOptions } from 'react-query';
import { EmailParticipantRead, EmailRead, EmailReadExtended } from 'types/api/deal/email';
import { ActivityTypeEnum } from 'types/api/deal/enum';
import { OutlookEmailParticipantRead, OutlookEmailRead, OutlookEmailReadWithBody } from 'types/api/deal/outlook_email';
import { logOutlookEmailAsync } from 'utils/email';

interface EmailTimelineEntryProps {
  record: EmailRead | OutlookEmailRead;
  timelineRefetch: () => void;
}
type GenericEmailReadExtended = EmailReadExtended | OutlookEmailReadWithBody;
interface EmailTimelineEntryBaseProps<T extends GenericEmailReadExtended> {
  record: EmailRead | OutlookEmailRead;
  queryOptions: UseQueryOptions<T>;
  actionMenu: ReactNode;
  avatarContents: ReactNode;
}

function GridItem({ label, content }: { label: string; content: string }) {
  return (
    <>
      <Grid item xs={5} style={{ textAlign: 'right' }}>
        <Typography variant="body1" fontWeight={600}>
          {label}
        </Typography>
      </Grid>
      <Grid item xs={19}>
        <Typography variant="body1">{content}</Typography>
      </Grid>
    </>
  );
}

const EmailTimelineEntryBase = <T extends GenericEmailReadExtended>({
  record,
  queryOptions,
  actionMenu,
  avatarContents
}: EmailTimelineEntryBaseProps<T>) => {
  const [expanded, setExpanded] = useState(false);

  const datetime = dayjs(record.sent_date).toDate();

  // Use the new participant fields
  const senderList = record.from_participant ? [record.from_participant] : [];
  const toList = record.to_participants || [];
  const ccList = record.cc_participants || [];

  const {
    data: emailData = null,
    isLoading,
    isError
  } = useQuery<T>({
    ...queryOptions,
    enabled: expanded
  });

  const activityBodyPreview = record.body_preview;

  // Use fetched email data for activityBody
  const activityBody = isLoading ? (
    <Stack alignItems={'center'}>
      <CircularProgress size={24} />
    </Stack>
  ) : isError ? (
    'Failed to load email content.'
  ) : (
    (emailData?.body_html && <div dangerouslySetInnerHTML={{ __html: emailData?.body_html }} />) ||
    emailData?.body_plaintext ||
    activityBodyPreview
  );

  const title = (
    <>
      <LightTooltip
        title={
          <Grid container columnSpacing={0.75} sx={{ p: 1 }} columns={24} onClick={(event) => event.stopPropagation()}>
            <GridItem label="Subject:" content={record.subject} />
            <GridItem label="From:" content={formatEmailList(senderList, true)} />
            <GridItem label="To:" content={formatEmailList(toList)} />
            <GridItem label="Cc:" content={formatEmailList(ccList)} />
          </Grid>
        }
        arrow
      >
        <Typography style={{ fontWeight: 600, cursor: 'pointer' }}>
          {record.subject}
          <Typography style={{ color: 'grey' }} component={'span'}>
            {' '}
            from {_.get(senderList, '0.full_name', 'Unknown')}
          </Typography>
        </Typography>
      </LightTooltip>
    </>
  );

  const subtitle = `to ${formatEmailList(toList, true, !expanded)}`; // Show the full email list when expanded

  const activityFooter = (
    <Grid container spacing={1} sx={{ pt: 1 }}>
      {record.email_attachments.map((attachment) => (
        <Grid item key={attachment.id}>
          <DownloadAttachmentButton attachment={attachment} />
        </Grid>
      ))}
    </Grid>
  );

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <TimelineEntry
      datetime={
        <LightTooltip title={<div onClick={(event) => event.stopPropagation()}>{formatDateTimeString(datetime)}</div>}>
          <Stack direction={'row'} alignItems={'center'} spacing={0.5} onClick={handleExpandClick} sx={{ cursor: 'pointer' }}>
            <Box sx={{ pt: 0.25 }}>{formatDateString(datetime)}</Box>
          </Stack>
        </LightTooltip>
      }
      color={'primary'}
      avatarContents={avatarContents}
      title={
        <Box onClick={handleExpandClick} sx={{ cursor: 'pointer' }}>
          {title}
        </Box>
      }
      subtitle={subtitle}
      activityBody={activityBody}
      activityBodyPreview={activityBodyPreview}
      activityFooter={activityFooter}
      expanded={expanded}
      setExpanded={setExpanded}
      actionMenu={actionMenu}
    />
  );
};

export const EmailTimelineEntry = ({ record, timelineRefetch }: EmailTimelineEntryProps) => {
  const queryOptions = useMemo(() => getBaseQueryOptionsDealServiceV2<EmailReadExtended>(`/email/${record.id}`), [record.id]);
  const avatarContents = getIconNodeFromActivityType(ActivityTypeEnum.email);

  return (
    <EmailTimelineEntryBase<EmailReadExtended>
      record={record}
      queryOptions={queryOptions}
      actionMenu={<EmailActionMenu emailId={record.id as number} timelineRefetch={timelineRefetch} />}
      avatarContents={avatarContents}
    />
  );
};

export const OutlookEmailTimelineEntry = ({ record, timelineRefetch }: EmailTimelineEntryProps) => {
  const queryOptions = useMemo(
    () => getBaseQueryOptionsDealServiceV2<OutlookEmailReadWithBody>(`/outlook_email/${record.id}`),
    [record.id]
  );
  const avatarContents = (
    <LightTooltip title={'Outlook Email'}>
      <Box>
        <OutlookLogoIcon />
      </Box>
    </LightTooltip>
  );

  return (
    <EmailTimelineEntryBase<OutlookEmailReadWithBody>
      record={record}
      queryOptions={queryOptions}
      avatarContents={avatarContents}
      actionMenu={<SaveIconButton id={record.id as string} timelineRefetch={timelineRefetch} />}
    />
  );
};

interface SaveIconButtonProps {
  id: string;
  timelineRefetch: () => void;
}

const SaveIconButton: React.FC<SaveIconButtonProps> = ({ id, timelineRefetch }) => {
  const [loading, setLoading] = useState(false);

  const handleLog = async () => {
    try {
      setLoading(true); // Start loading
      await logOutlookEmailAsync(id);
      timelineRefetch();
    } catch (error) {
      Sentry.captureException('Unable to log Outlook email.');
      setLoading(false);
    }
  };

  return (
    <LightTooltip title={"Click to save to the Contact's timeline."}>
      <IconButton
        onClick={handleLog}
        disabled={loading} // Disable the button while loading
        color={'primary'}
      >
        <Box sx={{ width: '24px', height: '24px' }}>{loading ? <CircularProgress size={24} /> : <BookmarkAddOutlined />}</Box>
      </IconButton>
    </LightTooltip>
  );
};

const formatEmailList = (
  contactInfo: EmailParticipantRead[] | OutlookEmailParticipantRead[],
  namesOnly: boolean = false,
  truncate: boolean = false
) => {
  const MAX_LENGTH = 3;

  if (_.isArray(contactInfo) && _.size(contactInfo) > 0) {
    let formattedList = _.map(contactInfo, (obj) => (!namesOnly ? `${obj?.full_name} <${obj?.email_address}>` : obj?.full_name));

    if (truncate && formattedList.length > MAX_LENGTH) {
      const extraCount = formattedList.length - MAX_LENGTH;
      formattedList = _.take(formattedList, MAX_LENGTH);
      formattedList.push(`and ${extraCount} more`);
    }

    return formattedList.join(', ');
  } else {
    return '--';
  }
};
