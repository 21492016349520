// material-ui
// assets
import { DialogContent, Grid } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import * as Sentry from '@sentry/react';
// material-ui
import StandardField from 'components/form/standard/StandardField';
import { StandardForm } from 'components/form/standard/StandardForm';
import { StandardRecordFormTitle } from 'components/form/StandardRecordFormTitle';
import MainCard from 'components/MainCard';

// project import
import { CompanyMetadata } from 'constants/objectMetadata/companyMetadata';
import useAuth from 'hooks/useAuth';
import React from 'react';

// project import
import { Company } from 'types/company';
import { COMPANY_DELETE_PERMISSIONS } from 'types/permission';
import { StandardFormProps } from 'types/record';
import { FormSubmitAction } from 'types/standardForm';
import { createCompanyAsync, deleteCompanyAsync, updateCompanyAsync } from 'utils/company';
// third party

export const CompanyForm = ({
  record,
  incomingChanges,
  onCancel,
  successCallback,
  open,
  showAddAnother = true
}: StandardFormProps<Company>) => {
  const { checkPermissions } = useAuth();
  const canDelete = checkPermissions(COMPANY_DELETE_PERMISSIONS);

  const createFn = async (company: Company, submitAction: FormSubmitAction = FormSubmitAction.save) => {
    try {
      const record = await createCompanyAsync(company);
      if (typeof successCallback === 'function') successCallback(record);
    } catch (error) {
      Sentry.captureException('Unable to create Company.');
    }
  };

  const updateFn = async (id: number | string, company: Partial<Company>) => {
    try {
      const record = await updateCompanyAsync(id, company);
      if (typeof successCallback === 'function') successCallback(record);
    } catch (error) {
      Sentry.captureException('Unable to update Company.');
    }
  };

  const deleteFn = async (id: number | string) => {
    try {
      await deleteCompanyAsync(id);
      if (typeof successCallback === 'function') successCallback();
    } catch (error) {
      Sentry.captureException('Unable to delete Company.');
    }
  };

  return (
    <StandardForm<Company>
      metadata={CompanyMetadata}
      record={record}
      updateFn={updateFn}
      createFn={createFn}
      deleteFn={canDelete ? deleteFn : undefined}
      displayName={'Company'}
      onCancel={onCancel}
      incomingChanges={incomingChanges}
      showAddAnother={showAddAnother}
      open={open}
    >
      {({ StandardFormActionsInstance }) => (
        <>
          <StandardRecordFormTitle recordName={'Company'} isEdit={!!record?.id} onClose={onCancel} />
          <DialogContent>
            <MainCard>
              <Grid container columnSpacing={2} rowSpacing={1}>
                <Grid item xs={12}>
                  <StandardField field={CompanyMetadata.name} />
                </Grid>
                <Grid item xs={12}>
                  <StandardField field={CompanyMetadata._address_search} />
                </Grid>
                <Grid item xs={12}>
                  <StandardField field={CompanyMetadata.line_1} showLabel={false} />
                </Grid>
                <Grid item xs={12}>
                  <StandardField field={CompanyMetadata.line_2} showLabel={false} />
                </Grid>
                <Grid item xs={12}>
                  <StandardField field={CompanyMetadata.city} showLabel={false} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <StandardField field={CompanyMetadata.state} showLabel={false} />
                </Grid>
                <Grid item xs={12} sm={8}>
                  <StandardField field={CompanyMetadata.postal_code} showLabel={false} />
                </Grid>
              </Grid>
            </MainCard>
          </DialogContent>
          <DialogActions>{StandardFormActionsInstance}</DialogActions>
        </>
      )}
    </StandardForm>
  );
};
