import _ from 'lodash';
import { dispatch } from 'store';
import { openErrorNotification } from 'store/reducers/common';

// Action Creators
import { dealService } from 'utils/axios';
import { openNotification } from 'utils/notistack';

export async function deleteEmailAsync(id: number | string) {
  try {
    const response = await dealService.delete(`/email/${id}`);
    if (_.isArray(response.data)) {
      openNotification(`Email removed.`, 'success');
    }
  } catch (error) {
    dispatch(openErrorNotification('Error removing email.'));
    throw error;
  }
}

export async function logOutlookEmailAsync(id: string) {
  try {
    const response = await dealService.post(`/outlook_email/log_outlook_email/${id}`);
    if (_.isArray(response.data)) {
      openNotification(`Email saved.`, 'success');
    }
  } catch (error) {
    dispatch(openErrorNotification('Error logging email.'));
    throw error;
  }
}
