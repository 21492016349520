import RolePermissions from 'components/form/RolePermissions';
import { TextFieldString } from 'components/form/TextFieldString';
import { ObjectMetadata } from 'types/standardForm';
import * as Yup from 'yup';

export enum PermissionScope {
  all = 'all',
  team = 'team',
  individual = 'individual',
  none = 'none'
}

interface PermissionLevel {
  viewer: PermissionScope;
  editor: PermissionScope;
  deleter: PermissionScope;
}

export interface PermissionsUIStructure {
  organization: {
    settings: boolean;
    export: boolean;
  };
  deal: PermissionLevel;
  contact: PermissionLevel;
  company: PermissionLevel;
  email: PermissionLevel;
}

const getBaseUIPermissionsObject: () => PermissionsUIStructure = () => ({
  organization: {
    settings: false,
    export: false
  },
  deal: {
    viewer: PermissionScope.individual,
    editor: PermissionScope.individual,
    deleter: PermissionScope.individual
  },
  contact: {
    viewer: PermissionScope.individual,
    editor: PermissionScope.individual,
    deleter: PermissionScope.individual
  },
  company: {
    viewer: PermissionScope.individual,
    editor: PermissionScope.individual,
    deleter: PermissionScope.individual
  },
  email: {
    viewer: PermissionScope.all,
    editor: PermissionScope.none,
    deleter: PermissionScope.individual
  }
});

export const RoleMetadata: ObjectMetadata = {
  name: {
    fieldName: 'name',
    displayName: 'Role Name',
    initialValue: '',
    _schema: Yup.string().max(255, 'Role Name must be less than 256 characters.'),
    component: TextFieldString
  },
  permissions_ui: {
    fieldName: 'permissions_ui',
    displayName: 'Permissions',
    initialValue: getBaseUIPermissionsObject(),
    _schema: Yup.object(),
    component: RolePermissions
  }
};
