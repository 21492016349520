import { DialogContent, Grid } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import StandardField from 'components/form/standard/StandardField';
import { StandardForm } from 'components/form/standard/StandardForm';
import { StandardRecordFormTitle } from 'components/form/StandardRecordFormTitle';
import MainCard from 'components/MainCard';
import { RoleMetadata } from 'constants/objectMetadata/roleMetadata';
import React from 'react';
import { Role } from 'types/api/user_management/user';
import { StandardFormProps } from 'types/record';
import { FormSubmitAction } from 'types/standardForm';
import { createRoleAsync, deleteRoleAsync, updateRoleAsync } from 'utils/role';
import * as Sentry from '@sentry/react';

export const RoleForm = ({ record, incomingChanges, onCancel, successCallback, open, showAddAnother = true }: StandardFormProps<Role>) => {
  const createFn = async (role: Role, submitAction: FormSubmitAction = FormSubmitAction.save) => {
    try {
      const record = await createRoleAsync(role);
      if (typeof successCallback === 'function') successCallback(record);
    } catch (error) {
      Sentry.captureException('Unable to create a role.');
    }
  };

  const updateFn = async (id: number | string, role: Partial<Role>) => {
    try {
      const record = await updateRoleAsync(id, role);
      if (typeof successCallback === 'function') successCallback(record);
    } catch (error) {
      Sentry.captureException('Unable to update a role.');
    }
  };

  const deleteFn = async (id: number | string) => {
    try {
      await deleteRoleAsync(id);
      if (typeof successCallback === 'function') successCallback();
    } catch (error) {
      Sentry.captureException('Unable to delete a role.');
    }
  };

  return (
    <StandardForm<Role>
      metadata={RoleMetadata}
      record={record}
      updateFn={updateFn}
      createFn={createFn}
      deleteFn={deleteFn}
      displayName={'Role'}
      onCancel={onCancel}
      incomingChanges={incomingChanges}
      showAddAnother={showAddAnother}
      open={open}
      maxWidth={'sm'}
    >
      {({ StandardFormActionsInstance }) => (
        <>
          <StandardRecordFormTitle recordName={'Role'} isEdit={!!record?.id} onClose={onCancel} />
          <DialogContent>
            <MainCard>
              <Grid container columnSpacing={2} rowSpacing={1}>
                <Grid item xs={12}>
                  <StandardField field={RoleMetadata.name} />
                </Grid>
                <Grid item xs={12}>
                  <StandardField field={RoleMetadata.permissions_ui} />
                </Grid>
              </Grid>
            </MainCard>
          </DialogContent>
          <DialogActions>{StandardFormActionsInstance}</DialogActions>
        </>
      )}
    </StandardForm>
  );
};
