// MUI
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Chip, Menu, MenuItem, Stack } from '@mui/material';
import _ from 'lodash';
import { DEAL_STATUS_OPTIONS } from 'pages/deal/constants/deal_enums';
import { PROSPECT_STATUS_OPTIONS } from 'pages/deal/constants/prospect';
import { getClosedDealFormIncomingChanges } from 'pages/deal/utils/deal_form';
import React, { useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { startEditingRecord } from 'store/reducers/record';
import { ProspectStatusEnum } from 'types/api/deal/enum';
import { SimpleOptionWithColor } from 'types/api/deal/form';
import { DealStatus } from 'types/deal';
import { FormIdentifier } from 'types/record';
import { updateDealAsync } from 'utils/deal';

type MenuChipProps = {
  value: string;
  options: SimpleOptionWithColor[];
  handleOptionClick: (option: string) => void;
};

export function MenuChip({ value, options, handleOptionClick }: MenuChipProps) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleChipClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const optionsDict = useMemo(() => _.keyBy(options, 'key'), [options]);

  return (
    <div>
      <Stack justifyContent={'space-between'} direction={'row'} onClick={handleChipClick}>
        <Chip
          id={value}
          color={_.get(optionsDict, [value, 'color'], 'secondary')}
          size={'small'}
          variant={'light'}
          label={
            <Stack direction={'row'} justifyContent={'center'} alignItems={'center'}>
              {_.get(optionsDict, [value, 'label'], 'Unknown')}
              {Boolean(anchorEl) ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </Stack>
          }
          sx={{ cursor: 'pointer' }}
        />
      </Stack>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        {_.map(
          _.filter(options, (x) => x.key !== value),
          (option) => (
            <MenuItem
              key={option.key}
              onClick={() => {
                setAnchorEl(null);
                handleOptionClick(`${option.key}`);
              }}
            >
              <Chip
                id={`${option.key}`}
                color={option.color}
                label={option.label}
                size={'small'}
                variant={'light'}
                sx={{ cursor: 'pointer' }}
              />
            </MenuItem>
          )
        )}
      </Menu>
    </div>
  );
}

export const DealStatusChip = ({ value, recordId }: { recordId: number; value: DealStatus }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const handleOptionClick = async (option: string) => {
    if (option === DealStatus.lost && recordId) {
      // ** NOTE: Duplicate logic found in DealMetadata.status.additionalChangeHandler. Be sure to make updates there and vice versa. **
      const incomingChanges = { status: DealStatus.lost, lost_date: new Date() };
      // Open edit dialog
      startEditingRecord(recordId, FormIdentifier.DealLostForm, incomingChanges);
    } else if (option === DealStatus.closed && recordId) {
      const incomingChanges = getClosedDealFormIncomingChanges();
      // Open edit dialog
      startEditingRecord(recordId, FormIdentifier.DealClosedForm, incomingChanges);
    } else if (option === DealStatus.open && recordId) {
      const record = await updateDealAsync(recordId, { status: DealStatus.open }, navigate);
      queryClient.invalidateQueries();
    }
  };

  return <MenuChip value={value} options={DEAL_STATUS_OPTIONS} handleOptionClick={handleOptionClick} />;
};

export const ProspectStatusChip = ({ value, recordId }: { recordId: number; value: ProspectStatusEnum }) => {
  const handleOptionClick = (option: string) => {
    // Update status
    const incomingChanges =
      option === ProspectStatusEnum.converted ? { status: ProspectStatusEnum.converted, conversion_date: new Date() } : { status: option };

    // Open edit dialog
    startEditingRecord(recordId, FormIdentifier.ContactForm, incomingChanges);
  };

  return <MenuChip value={value} options={PROSPECT_STATUS_OPTIONS} handleOptionClick={handleOptionClick} />;
};
